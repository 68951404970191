import request from "../providers/RestServiceProvider";
import * as Locations from "../constants/Locations";
import { ERR_SOMETHING_WENT_WRONG } from "../constants/GlobalConstants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from "date-fns/locale";

export const getCatCases = async (pageNumber, ItemsPerPage) => {
    return await request({
        url: Locations.BASE_URL + "/CAT/GetCAT_ItemListAsync?" + "PageNumber=" + pageNumber + "&ItemsPerPage=" + ItemsPerPage,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    })
};

export const getReportsCases = async (filter, pageNumber, ItemsPerPage) => {
    return await request({
        url: Locations.BASE_URL + "/CaseFilters/" + `${filter}` + "?PageNumber=" + pageNumber + "&ItemsPerPage=" + ItemsPerPage,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};

export const savePatientEstimateSentConfirmation = async (caseId, payload) => {
    return await request({
        url: Locations.BASE_URL + "/Case/" + `${caseId}` + "/Confirmations",
        "content-type": "application/x-www-form-urlencoded",
        method: "POST",
        data: payload,
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    }).catch(err => {
        toast(ERR_SOMETHING_WENT_WRONG, { type: 'error' });
    });;
};

export const acknowledgeEmr = async (acknowledgeEmrIds) => {
    const {caseId, sectionId, txnId} = acknowledgeEmrIds;

    return await request({
        url: Locations.ACKNOWLEDGE_EMR_URL + "?caseId=" + caseId + "&sectionId=" + sectionId + "&txnId=" + txnId,
        method: 'PUT',
        resolveWithFullResponse: true
    }).then((response) => {
        let responseData = {};
        if (response.status === 200) {
            responseData = response.data
        } else if (response.status === 204) {
            responseData = {
                httpStatusCode: response.status
            }
        }
        
        return responseData;
    })
}

const downloadFileFromResponse = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const getDataReport = async (dataTypeID, OrgIds) => {
    // Updated the URL by creating Admin base URL
    return await request({
        url: Locations.REPORT_DOWNLOAD_URL + dataTypeID + "?surgicalLocationIds=" + OrgIds,
        method: "GET",
    }).then((response) => {
        if (response.data) {
            var fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].trim();
            var filetype = response.headers['content-type'];
            fileName && filetype && downloadFileFromResponse({
                data: response.data,
                fileName: fileName,
                fileType: filetype,
            })
            return {
                status: "Success",
                statusCode: response.status,
            };
        } else {
            return {
                status: "Failed",
                statusCode: response.status,
            };
        }
    }).catch(error => console.log(error));
};

export const getDataTypes = async () => {
    return await request({
        url: Locations.ADMIN_API_URL + "/MasterAdminDataUploadType/GetListAsync",
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};


export const getOrganizationList = async () => {
    return await request({
        url: Locations.ADMIN_API_URL + "/DataUpload/GetProcedureLocations",
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    });
};

export const getFilteredReportsData = async (requestData, filter) => {
    return await request({
        url: Locations.BASE_URL + "/CaseFilters/" + `${filter}`,// Update with the back end post API URL WIP ,
        method: 'POST',
        data: requestData
    }).then((response) => {

        if (response.status === 'Success') {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    })
}

export const getSearchFiltersReferenceData = async (serachFilterControls) => {
    return await request({
        url: Locations.GET_REFERENCE_URL,
        method: 'POST',
        data: serachFilterControls
    }).then((response) => {
        if (response.status === 'Success') {
            return {
                responseData: response.payload,
            };
        } else {
            return {
                responseData: response.payload,
            };
        }
    });
};

// This `getAmendmentsByCaseId` function is used to get CAT amendments list providing Case Id, Section Id and Transaction Id as params
export const getAmendmentsByCaseId = async (caseId, sectionId, txnId) => {
    return await request({
        url: Locations.GET_AMENDMENTS_BY_CASE_ID + "?CaseId=" + caseId + "&SectionId=" + sectionId + "&TxnId=" + txnId,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                status: "Success",
                responseData: response.payload,
            };
        } else {
            return {
                status: "Failed",
                responseData: response.payload,
            };
        }
    })
};

// This function is used to get data for vendor confiramtion due
export const getVendorConfirmationData = async (data) => {
    return await request({
        url: Locations.GET_VENDOR_CONFIRMATION_DUE,
        method: 'POST',
        data: data
    }).then((response) => {
        if (response.status === 'Success') {
            return {
                responseData: response.payload,
            };
        } else {
            return {
                responseData: response.payload,
            };
        }
    });
};

// This function is used to get the product list pending for vendor confirmation
export const getPendingVendorConfirmationData = async (caseId) => {
    return await request({
        url: `${Locations.GET_VENDOR_CONFIRMATION_DUE}/${caseId}/Products`,
        method: "GET",
    }).then((response) => {
        if (response) {
            return {
                type: 'success',
                data: response.payload 
            }
        }
    }).catch(error => {
        return {
            type: 'error',
            data: error
        }
    });
};

// This function is used to call the API to send request for vendor confirmation
export const sendRequestForVendorConfirmation = async (caseId) => {
    return await request({
        url: `${Locations.GET_VENDOR_CONFIRMATION_DUE}/${caseId}/SendNotification`,
        method: "POST",
    }).then((response) => {
        if(response.status == 204) {
            return {
                type: 'error',
                data: response
            }
        } else {
            return {
                type: 'success',
                data: response
            }
        }
    }).catch(error => {
        return {
            type: 'error',
            data: error
        }
    });
};

const ReportService = {
    getCatCases,
    acknowledgeEmr,
    getReportsCases,
    getDataReport,
    getDataTypes,
    getOrganizationList,
    getFilteredReportsData,
    getSearchFiltersReferenceData,
    savePatientEstimateSentConfirmation,
    getAmendmentsByCaseId,
    getVendorConfirmationData,
    getPendingVendorConfirmationData,
    sendRequestForVendorConfirmation
};

export default ReportService;
export const KEY_AUTHENTICATION_TOKEN = "Authorization";
export const KEY_APP_URLS = "ApplicationURL";
//To be updated with the server URL later


export const OAUTH_GRANT_TYPE = "password";
export const REFRESHTOKEN_GRANT_TYPE = "refresh_token";
export const OAUTH_SCOPE = "surgiscriptapi profile openid tenantId offline_access";
export const OAUTH_CLIENT_ID = "surgiscript.react";
export const OAUTH_CLIENT_SECRET = "secret";

export const KEY_TENANT_LIST_PAGE = "tenantListPage";
export const KEY_DASHBOARD_PAGE = "dashboardPage";

export const KEY_FAILED = "failed";
export const KEY_REFRESH_TOKEN = "REFRESH_TOKEN";
export const KEY_RETURN_URL = "RETURN_URL";
export const LOGIN_DISPLAY_NAME = "LOGIN_DISPLAY_NAME";
export const LOGIN_USER_NAME = "LOGIN_USER_NAME";
export const LOGIN_USER_ID = "LOGIN_USER_ID";
export const LOGIN_TENANT_KEY = "LOGIN_TENANT_KEY";
export const LOGIN_TENANT_IMAGE_URL = "LOGIN_TENANT_IMAGE_URL";
export const SELECTED_TENANT = 'SELECTED_TENANT'

//Labels
export const SURGICAL_DASHBOARD = "Surgical Dashboard";
export const LOGIN = "Login";
export const FORGOT_PASSWORD = "Forgot Password?";
export const RESET_PASSWORD = "Reset Password";
export const TERMS_AND_CONDITION =
  "By logging in, you are agreeing to Medtel's terms of use.";

export const PATIENT_INFO_SECTION_ID = "1";
export const CANCEL_CASE_STATUS_ID = 2;
export const SCHEDULING_ID = "4";
export const CASE_SUMMARY_ID = "7";
export const CREATE_CASE_SECTION_ID = "11";
export const CANCEL_CASE_SECTION_ID = "12";

export const PATIENT_INFORMATION_PAGE_URL = "/PatientInformation";
export const FINANCIAL_PAGE_URL = "/Financial";
export const PROCEDURE_DETAILS_PAGE_URL = "/ProcedureDetails";
export const SCHEDULING_PAGE_URL = "/Scheduling";
export const IMPLANTS_AND_PRODUCTS_PAGE_URL = "/ImplantsandProducts";
export const CLINICAL_PAGE_URL = "/Clinical";
export const CASE_SUMMARY_PAGE_URL = "/CaseSummary";
export const DOC_MANAGEMENT_PAGE_URL = "/DocManagement";
export const COMMENTS_PAGE_URL = "/Comments";
export const ACTIVITY_LOGS_PAGE_URL = "/ActivityLogs";
export const CANCEL_CASE_PAGE_URL = "/CancelCase";
export const DASHBOARD_PAGE_URL = "/Dashboard";
export const LOGIN_PAGE_URL = "/Login";

export const ACTION_BUTTON_CREATE_CASE_ID = "0";
export const ACTION_BUTTON_NEXT_ID = "1";
export const ACTION_BUTTON_SAVE_ID = "2";
export const ACTION_BUTTON_SAVE_AND_NEXT_ID = "3";
export const ACTION_BUTTON_BACK_ID = "4";
export const BLANK_VALUE = "";
export const NOT_APPLICABLE = "N/A";
export const GLOBAL_VALUE_DOT = ".";
export const LBL_PATIENT_INFORMATION = "Patient Information";
export const PERMISSION_TYPE_ID_DISABLE_FIELD = "5";
export const FIELD_DEFAULT_SELECTION_VALUE = "-1";
export const EMAIL_REGEX =
  '/^(("[w-s]+")|([w-]+(?:.[w-]+)*)|("[w-s]+")([w-]+(?:.[w-]+)*))(@((?:[w-]+.)*w[w-]{0,66}).([a-z]{2,6}(?:.[a-z]{2})?)$)|(@[?((25[0-5].|2[0-4][0-9].|1[0-9]{2}.|[0-9]{1,2}.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2}).){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})]?$)/i';
export const CASE_SECTION_ID = "CASE_SECTION_ID";

//Date and time formates
export const ALLOWED_DATE_FORMATES = ["MM/dd/yyyy", "dd/MM/yyyy", "yyyy/MM/dd"];
export const DEFAULT_DATE_FORMATES = "MM/dd/yyyy";
export const DOCUMENT_DATE_FORMAT = "MM/DD/YYYY h:mm:ss A";
export const COMMON_DATE_FORMAT = "MM/DD/YYYY";
export const COMMON_TIME_FORMAT = "h:mm:ss A";
export const NOTIFICATION_DATE_FORMAT = "DD/MMM/YYYY HH:mm:ss";
export const DASHBOARD_START_DATE_FORMAT = "YYYY-MM-DDT00:00:00";// "MM-DD-YY 00:00:00.000";
export const DASHBOARD_END_DATE_FORMAT = "YYYY-MM-DDT00:00:00";//"MM-DD-YY 23:59:59.000";
export const ALLOWED_TIME_FORMATES = ["hh:mm a", "HH:mm", "h:mm:ss A", "HH:mm:ss"];
export const LAST_SENT_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";

// control types
export const BUTTON = "Button";
export const BUTTONS = "Buttons";
export const SELECTION = "Selection";
export const SELECTION_KEY_VALUE = "SelectionKeyValue";
export const DATE = "Date";
export const TIME = "Time";
export const TEXT = "Text";
export const NUMBER = "Number";
export const TYPEAHEAD = "Typeahead";
export const PHONE_NUMBER = "PhoneNumber";
export const ZIPCODE = "ZipCode";
export const EMAIL = "Email";
export const CHECKBOX = "Checkbox";
export const IMAGE_BUTTON = "Image Buttons";
export const SELECT_FILE = "SelectFile";
export const ACTIVEHIDDENFIELDS = "ActiveHiddenFields";
export const LABEL = "Label";
export const MULTI_SELECT = "MultiSelect";

// ---RuleEngin validation keys---
// field additional properties keys
export const MAX_LENGTH_KEY = "MaxLength";
export const MIN_LENGTH_KEY = "MinLength";
export const TEXT_FORMAT = "Format";
export const NUMBERS_ONLY = "NumbersOnly";
export const PHONE_NUMBER_ONLY = "PhoneNumberOnly";
export const TEXT_ONLY = "TextOnly";
export const CALCULATE_AGE = "CalculateAge";
export const CALCULATE_BMI = "CalculateBMI";
export const CALCULATE_BMI_RATIO = "CalculateBMIAgeRatio";
export const CALCULATE_TOTAL_HEIGHT = "CalculateTotalHeight";
export const FORMAT = "Format";
export const EQUAL_TO = "EqualTo";
export const NOT_EQUAL_TO = "NotEqualTo";
export const GREATER_THAN = "GreaterThan";
export const LESS_THAN = "LessThan";
export const STRING = "string";
export const OBJECT = "object";
export const CHIPS = "Chips";
export const PATIENTID = "PatientId";
export const ENCOUNTERID = "EncounterId";
export const TYPE_PACKET = "Packet";

export const INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE =
  "InputSelectionMoreThanProvidedValue";
export const INPUT_SELECTION_MORE_THAN_PROVIDED_VALUE_DUETO_DEPENDENT_FIELD =
  "InputSelectionMoreThanProvidedValueDueToDependentFieldValue";
export const COUNT_OF_TARGET_FIELD_VALUES_GREATER_THAN_PROVIDED_VALUE =
  "CountOfTargetFieldValuesGreaterThanProvidedValue";
export const CONFIGURE_MAX_LENGTH_BY_FIELD_VALUE =
  "ConfigureInputLengthByDependentFieldValue";
export const CONFIGURE_MAX_LENGTH_NOT_EQUALTO_FIELD_VALUE =
  "ConfigureInputLengthByDependentFieldValueNotEqualToValueProvided";
export const DEPENDENT_FIELD_ID = "DependentFieldId";
export const DEPENDENT_FIELD_VALUE = "DependentFieldValue";
export const ON_SELECTION_MANAGE_SECTION_GROUPS =
  "OnSelectionManageSectionGroups";
export const DISABLE_FUTURE_DATE_KEY =
  "DisableTargetFieldIfFieldDateGreaterThanCurrentDate";
export const CALCULATE_DATE_AHEAD =
  "CalculateDateAhead";
export const DISABLE_PAST_DATE_KEY =
  "DisableTargetFieldIfFieldDateLowerThanCurrentDate";
export const CONFIGURE_INPUT_PROPERTIES_OF_TARGET_FIELD =
  "ConfigurePropertiesOfTargetField";

export const MAKE_TARGET_FIELD_A_REQUIRED_FIELD =
  "MakeTargetFieldARequiredField";
export const MAKE_TARGET_FIELD_NOT_A_REQUIRED_FIELD =
  "MakeTargetFieldNotARequiredField";
export const MAKE_TARGET_FIELD_A_REQUIRED_FIELD_WHEN_FIELD_VALUE_ASSIGNED_DEPENDENT_FIELD =
  "MakeTargetFieldARequiredFieldWhenFieldValueAssignedToDependentField";
export const IS_HIDDEN_WHEN_DEPENDENT_FIELD_DOES_NOT_HAVE_VALUE =
  "IsHiddenWhenDependentFieldDoesNotHaveValue";
export const SHOW_TARGET_FIELD_WHEN_FIELD_VALUE_ASSIGNED_TO_DEPENDENT_FIELD =
  "ShowTargetFieldWhenFieldValueAssignedToDependentField";
export const CHANGE_FIELD_PERMISSION_BY_DEPENDENT_FIELDS_VALUE_BY_CONDITION =
  "ChangeFieldPermissionByDependentFieldsValueByCondition";
export const ON_CLICK_EXECUTE_SERVICE_API = "OnClickExecuteServiceAPI";
export const DISABLE_FIELD_DUE_TO_NO_VALUE_IN_DEPENDENT_FIELDS =
  "DisableFieldDueToNoValueInDependentFields";
export const DISABLE_TARGET_FIELD_IF_NO_VALUE_BY_CONDITION =
  "DisableTargetFieldIfNoValueByCondition";
export const DO_NOT_OVERRIDE_TARGET_FIELD_VALIDATIONS_WHEN_FIELD_HAS_VALUE =
  "DoNotOverrideTargetFieldValidationsWhenFieldHasValue";
export const OVERRIDE_TARGET_FIELD_VALIDATIONS_WHEN_FIELD_HAS_VALUE =
  "OverrideTargetFieldValidationsWhenFieldHasValue";
export const MAKE_TARGET_FIELD_A_REQUIRED_FIELD_ON_SELECTION =
  "MakeTargetFieldARequiredFieldOnSelection";
export const ON_ADD_EXECUTE_RULES = "OnAddExecuteRules";
export const ON_CLICK_SHOW_SECTION_AS_POPUP = "OnClickShowSectionAsPopup";
export const ON_CLICK_CLOSE_POPUP = "OnClickClosePopup";
export const DISABLE_TARGET_FIELD_IF_FIELD_HAS_VALUE =
  "DisableTargetFieldIfFieldHasValue";
export const VISIBLE_WHEN_CASE_STATUS_IS_CANCELLED =
  "VisibleWhenCaseStatusIsCancelled";
export const HIDE_WHEN_CASE_STATUS_IS_NOT_CANCELLED =
  "HideWhenCaseStatusIsNotCancelled";
export const DISABLE_TARGET_FIELD_IF_NO_VALUE = "DisableTargetFieldIfNoValue";
export const DISABLE_TARGET_FIELD_IF_INVALID_VALUE = "DisableTargetFieldIfInvalidValue";
export const DISABLE_TARGET_FIELD_IF_INVALID_TIME = "DisableTargetFieldIfInvalidTime";
export const ON_CLICK_SHOW_SECTION_POP_UPWITH_DF = "OnClickShowSectionAsPopupWithDependentFields";
export const ASSIGN_VALUE_DUE_TO_DEPENDENT_FIELD = "AssignValueToTargetFieldDueToDependentField";
export const ON_CHANGE_DATA_TO_CLEAR_DEPENDENT_FIELD = "OnChangeDataToClearDueToDependentFieldValue";
export const SHOW_TARGET_FIELD_AND_DEPENDENT_FIELD_HAVE_VALUE = "ShowTargetFieldIfFieldAndDependentFieldHaveValue";
export const HIDE_TARGET_FIELD_AND_DEPENDENT_FIELD_HAVE_VALUE = "HideTargetFieldIfFieldAndDependentFieldDoesNotHaveValue";
export const ON_CHANGE_EXECUTE_ADDITIONAL_PROPERTIES_OF_TARGET_FIELD = "OnChangeExecuteAdditionalPropertiesOfTargetField";
export const SHOW_TARGET_FIELD_IF_DEPENDENT_FIELD_HAS_ANY_VALUE = "ShowTargetFieldIfDependentFieldHasAnyValue";
export const HIDE_TARGET_FIELD_IF_DEPENDENT_FIELD_DOES_NOT_HAVE_ANY_VALUE = "HideTargetFieldIfDependentFieldDoesNotHaveAnyValue";
export const ON_CLICK_SHOW_POPUP = "OnClickShowPopup";
export const HIDE_TARGET_FIELD_IF_FIELD_DOES_NOT_HAVE_VALUE_IN_TABLE = "HideTargetFieldIfFieldDoesNotHaveValueInTable";
export const SHOW_TARGET_FIELD_IF_FIELD_HAVE_VALUE_IN_TABLE = "ShowTargetFieldIfFieldHasValueInTable";
export const SHOW_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAVE_VALUE_IN_TABLE = "ShowTargetFieldIfAdditionalFieldHasValueInTable";
export const HIDE_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAVE_VALUE_IN_TABLE = "HideTargetFieldIfAdditionalFieldHasValueInTable";
export const ON_SELECT_RELOAD_REFERENCE_DATA_IN_TARGET_FIELD_IN_TABLE = "OnSelectReloadReferenceDataInTargetFieldInTable";
export const SHOW_COMPLIANCE_TEXT_AND_EXECUTE_TRACKING = "ShowComplianceTextAndExecuteTracking";
export const DISABLE_DELETE_DUE_TO_DEPENDENT_FIELD_VALUE = "DisableDeleteDueToDependentFieldValue";
export const ON_CLICK_GENERATE_AND_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION = "OnClickGenerateAndAddValueToTargetFieldByCondition";
export const DELETE_FROM_TARGET_FIELD_BY_CONDITION = "DeleteFromTargetFieldByCondition";

// field validation keys
export const INVALID_DATE_KEY = "InvalidDate";
export const AGE_120YEARVALIDATION =
  "FieldAndCurrentDateDifferenceMoreThan120Years";
export const INPUT_LENGTH_ON_DEPENDENT_FIELD =
  "InputLengthEqualToProvidedValueDueToDependentFieldValue";
export const PROVIDED_VALUE = "ProvidedValue";
export const SHOW_FIELD_IF_FIELD_HAS_VALUE = "ShowTargetFieldIfFieldHasValue";
export const HIDE_FIELD_IF_FIELD_DOES_NOT_HAVE_VALUE =
  "HideTargetFieldIfFieldDoesNotHaveValue";
export const HIDE_TARGET_FIELD_IF_FIELD_HAS_VALUE =
  "HideTargetFieldIfFieldHasValue";
export const PREVENT_DUPLICATE_ENTRIES = "PreventDuplicateEntries";
export const DATE_GREATER_THAN_CURRENT_DATE = "FieldDateGreaterThanCurrentDate";
export const DATE_LOWER_THAN_CURRENT_DATE = "FieldDateLowerThanCurrentDate";
export const ON_SELECT_ADD_VALUE_TO_TARGET_FIELD =
  "OnSelectAddValueToTargetField";
export const ON_SELECT_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION =
  "OnSelectAddValueToTargetFieldByCondition";
export const SHOW_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAS_VALUE =
  "ShowTargetFieldIfAdditionalFieldHasValue";
export const HIDE_TARGET_FIELD_IF_ADDITIONAL_FIELD_DOES_NOT_HAVE_VALUE =
  "HideTargetFieldIfAdditionalFieldDoesNotHaveValue";
export const MAKE_TARGET_FIELD_A_REQUIRED_FIELD_DUE_TO_ADDITIONAL_FIELD =
  "MakeTargetFieldARequiredFieldDueToAdditionalFieldValue";
export const DISABLE_TARGET_FIELD_IF_ADDITIONAL_FIELD_HAS_VALUE =
  "DisableTargetFieldIfAdditionalFieldHasValue";

export const CALCULATE_PATIENT_AGE_IN_YEARS_ON_PROCEDURE_DATE =
  "CalculatePatientAgeInYearsOnProcedureDate";
export const CALCULATE_DATE_DIFFERENCE_IN_DAYS =
  "CalculateDateDifferenceInDays";
export const DISPLAY_CONTROL_TEXT_BY_CONDITION =
  "DisplayControlTextByCondition";
export const ON_CHANGE_DATA_TO_CLEAR = "OnChangeDataToClear";
export const IS_REQUIRED_WHEN = "IsRequiredWhen";
export const ON_SELECT_HIDE_TARGET_FIELD = "OnSelectionHideTargetField";
export const ON_SELECT_SHOW_TARGET_FIELD = "OnSelectionShowTargetField";
export const ON_CHECK_SET_VALUES_FROM_OTHER_FIELDS =
  "OnCheckSetValuesFromOtherFields";
export const FORMAT_CHECK = "FormatCheck";
export const VALID_FILE_TYPE_SELECTED = "ValidFileTypeSelected";
export const VALID_TIME_CHECK = "ValidTimeCheck";
export const ON_DELETE_SET_TARGET_FIELD_VALUE = "OnDeleteSetTargetFieldValue";

export const INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE =
  "InputLengthEqualToProvidedValue";
export const INPUT_LENGTH_EQUAL_TO_PROVIDED_VALUE_DUE_TO_DEPENDENT_FIELD_VALUE =
  "InputLengthEqualToProvidedValueDueToDependentFieldValue";
export const INPUT_VALUE_EQUAL_TO_PROVIDED_VALUE =
  "InputValueEqualToProvidedValue";
export const INPUT_VALUE_OF_TARGET_FIELD_VALUE_COMPARED_TO_PROVIDED_VALUE =
  "InputValueOfTargetFieldValueComparedToProvidedValue";
export const INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE =
  "InputLengthGreaterThanProvidedValue";
export const ON_SELECT_RELOAD_REFERENCE_DATA_IN_TARGET_FIELD =
  "OnSelectReloadReferenceDataInTargetFields";
export const ON_CHANGE_SECTION_DATA_TO_CLEAR = "OnChangeSectionDataToClear";
export const PREVENT_DUPLICATE_ENTRY_IN_TARGET_FIELD =
  "PreventDuplicateEntryInTargetField";
export const ADD_ANOTHER_FIELD_IN_TARGET_FIELD = "AddAnotherRowInTargetField";
export const AUTO_INCREMENT_TARGET_FIELD_VALUE =
  "AutoIncrementTargetFieldValue";
export const DELETE_POSITION_AND_BED_TYPE = "DeletePositionAndBedTypeBtn";
export const DISABLE_TARGET_FIELD_IF_NO_INPUT_SELECTED =
  "DisableTargetFieldIfNoInputSelected";
export const DISABLE_TARGET_FIELD_IF_NO_VALUE_IN_FIELDS =
  "DisableTargetFieldIfNoValueInFields";
export const DISABLE_TARGET_FIELD_IF_NO_VALUE_IN_FIELDS_BY_CONDITION =
  "DisableTargetFieldIfNoValueInFieldsByCondition";
export const IS_VISIBLE_WHEN = "IsVisibleWhen";
export const ON_CLICK_ADD_VALUE_TO_TARGET_FIELD_BY_CONDITION =
  "OnClickAddValueToTargetFieldByCondition";
export const ON_CLICK_ADD_VALUE_TO_TARGET_FIELD =
  "OnClickAddValueToTargetField";
export const ON_CLICK_ADD_VALUE_TO_TARGET_FIELD_IN_CHIPS = "OnClickAddValueToTargetFieldInChips";
export const DATA_TO_CLEAR = "DataToClear";
export const TARGET_FIELD_OTHER_FIELD_DEFAULT_VALUES =
  "TargetFieldOtherFieldDefaultValues";
export const LOAD_REFERENCE_DATA = "LoadReferenceData";
export const LOAD_REFERENCE_DATA_BY_CONDITION = "LoadReferenceDataByCondition";
export const DEPENDENT_FIELD_INPUT_LENGTH_GREATER_THAN_PROVIDED_VALUE_BY_CONDITION =
  "DependentFieldInputLengthGreaterThanProvidedValueByCondition";
export const SHOW_TARGET_FIELD_IF_FIELD_HAS_ANY_VALUE = "ShowTargetFieldIfFieldHasAnyValue"
export const HIDE_TARGET_FIELD_IF_FIELD_DOES_NOT_HAVE_ANY_VALUE = "HideTargetFieldIfFieldDoesNotHaveAnyValue"
export const DISPLAY_FIELD_NAME_AND_FIELD_VALUE = "DisplayFieldNameAndFieldValue"
export const MAKE_TARGET_FIELD_NOT_REQUIRED = "MakeTargetFieldANotRequiredField"
export const ON_CLICK_COPY_CONTENT_FROM_TARGET_FIELD = "OnClickCopyContentFromTargetField"
export const ON_CLICK_OPEN_PRINT_DIALOG = "OnClickOpenPrintDialog"
export const SHOW_IMAGE_IF_FIELD_HAS_VALUE = "ShowImageOnTargetFieldIfFieldHasValue";

export const COMPARE_OTHER_FIELD_VALUES = "CompareOtherFieldValues";
export const DISABLE_TARGET_FIELD_BY_COMPARING_OTHER_FIELD_VALUES = "DisableTargetFieldByComparingOtherFieldValues";
export const HIDE_COLUMN_OF_TARGET_FIELD_WHEN_FIELD_DOES_NOT_HAVE_VALUE = "HideColumnOfTargetFieldWhenFieldDoesHaveValue";
export const SHOW_COLUMN_OF_TARGET_FIELD_WHEN_FIELD_HAS_VALUE = "ShowColumnOfTargetFieldWhenFieldHasValue";
export const DATE_COMPARED_TO_DEPENDENT_FIELD = "DateComparedToDependentField";
export const DISABLE_TARGET_FIELD_BY_COMPARISON_WITH_DEPENDENT_FIELD = "DisableTargetFieldByComparisonWithDependentField";
export const SET_VALUE_TO_TARGET_FIELD = "SetValueToTargetField";
export const RELOAD_SECTION = "ReloadSection";
export const UPDATE_VALUES_OF_DEPENDENT_FIELDS = "UpdateValuesOfDependentFields";
export const CONCATENATE_FIELD_TEXT = "ConcatenateFieldText";
export const DISABLE_TARGET_FIELD_IF_DEPENDENT_FIELD_DOES_NOT_HAVE_ANY_VALUE = "DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue";
//Validation constants/error mesages
export const ERR_INVALID_DATE = "Invalid date";
export const ERR_INVALID_DATE_FORMAT = "Invalid Date Format!";
export const ERR_INVALID_EMAIL = "Not a valid Email!";
export const ERR_VALUE_CANNOT_GREATER_THAN_MAX = "Value can not be greater than Max length!";
export const ERR_INVALID_INPUT = "Invalid input!";
export const ERR_SOMETHING_WENT_WRONG = "Something went wrong!";
export const ERR_FAILED_TO_SAVE_CASE_RETRY = "An error occurred. Please click on Retry to attempt save again or click on Cancel to remain on the same page.";
export const ERR_FAILED_TO_SAVE_CASE_CANCEL = "An error occurred. Please click Ok to remain on the same page and contact the administrator to review the application.";
export const ERR_CREATE_CASE_FAILURE = "Case failed to be created. Please try again.";


//Validation constants/info message
export const INFO_SELECTED_DATE_CANNOT_GREATER_THAN_CURRENT = "Date should be same or less than current date.";
export const INFO_SELECTED_DATE_SAME_OR_GREATER_THAN_CURRENT = "Date should be same or greater than current date.";
export const INFO_AGE_CANNOT_GREATER_THEN_120 = "Age can not be greater than 120 year!";
export const NAVIGATION_WARNING_MESSAGE = "There are unsaved changes to this case. Click OK to continue without saving your changes or cancel to return to case.";
export const SESSION_WARNING_MESSAGE = "Your session has timed out. Please Click OK to Login again."
export const AUTHORIZATION_ERROR_MESSAGE = "You are not authorized to view the case."
export const AUTHORIZATION_ERROR_MESSAGE_DATAUPLOAD = "You are not authorized to open this page."

export const LBL_YEARS = "years";
export const LBL_DAYS = "days";
export const LBL_EQUAL = "Equal";
export const LBL_EQUAL_TO = "EqualTo";
export const LBL_GREATER_THAN = "GreaterThan";
export const LBL_GREATER_THAN_EQUAL = "GreaterThanEqualTo";
export const LBL_LESS_THAN = "LessThan";
export const LBL_LESS_THAN_EQUAL = "LessThanEqualTo";

export const ON_DELETE_EXECUTE_RULES = "OnDeleteExecuteRules"
export const ON_CLICK_UPDATE_TARGET_FIELD = "OnClickUpdateTargetField"
export const PROCEDURE_WITHIN_24_HOURS = "ProcedureWithin24Hours"
export const LEGAL_GUARDIAN_FORM_NOT_SUBMITTED = "LegalGuardianFormNotSubmitted"

//Regex
export const NUMBER_ONLY = /^[0-9]+$/;

// Response constant
export const SUCCESS = "Success";
export const FAILED = "Failed";


export const PATIENTNAME = "Patient Name";
export const CASEID = "Case Id";
export const PROCEDUREDATE = "Procedure Date";
export const HOSPITALMRN = "MRN";

export const LBL_CONFIRM_ALL_PRODUCTS = "Confirm All Products";
export const INFO_ALL_PRODUCT_CONFIRMED = "All Products Confirmed.";

//Rep confirmation label and messages
export const LBL_CASE_ID = "Case ID";
export const LBL_PRIMARY_REP = "Primary Rep";
export const LBL_CONFIRMING_REP = "Confirming Rep";
export const LBL_HOSPITAL = "Hospital";
export const LBL_SURGEON_NAME = "Surgeon Name";
export const LBL_SURGERY_DATE = "Procedure Date";
export const LBL_SURGERY_TIME = "Procedure Time";
export const LBL_VIEW_CASE = "View Case";
export const LBL_PRODUCT = "Product";
export const LBL_PRODUCTS = "Products";
export const LBL_APPROVING = "Confirming Products";
export const LBL_ERROR_APPROVING = "Error Confirming";
export const LBL_APPROVED = "Confirmed";
export const PRODUCTS_CONFIRMED = "PRODUCTS_CONFIRMED";
export const ERR_SOMETHING_WENT_WRONG_TO_UPDATE_PRODUCTS = "Something went wrong. Please use the Medtel Application to confirm products.";
export const ERR_CASE_ID_NOT_AVAILABLE = "Could not get caseId. Instead now try to search case directly in dashboard.";

export const LBL_VIEW_DOCUMENT = "View Document";

export const SIGNOUT_TIME_IN_MS = 1800000;

export const DASHBOARD_DEFAULT_PAGE_NUMBER = 1;
export const DASHBOARD_DEFAULT_ITEM_PER_PAGE = 100;

export const DEFAULT_PER_PAGE_OPTIONS = [100, 150, 200];

//workqueue label and codes
export const LBL_WORK_QUEUES = "Work Queues";
export const LBL_INSURANCE_AUTHORIZATION = "Insurance Authorization";
export const LBL_PRESURGICAL_TESTING_AT_HOSPITAL = "Presurgical Testing At Facility";
export const LBL_PREADMISSION_TESTING_AT_HOSPITAL_2 = "Preadmission Testing At Facility";
export const LBL_PENDING_CONFIRMATION_NUMBER = "Pending Scheduling Confirmation";
export const LBL_INPATIENT_ADMIT_TYPE = "CMS Inpatient Only";
export const LBL_CASE_AMENDMENT_TRACKER = "Case Amendment Tracker";
export const LBL_VENDOR_CONFIRMATION_DUE = "Vendor Confirmation Due";
export const RepConfirmationDue = "RepConfirmationDue";
export const NoInsuranceAuthorizationProvided = "NoInsuranceAuthorizationProvided";
export const IncompleteInsuranceAuthorization = "IncompleteInsuranceAuthorization";
export const PreSurgicalTestingatHospital = "PreSurgicalTestingatHospital";
export const PreAdmissionTestingAtHospital2 = "PreAdmissionTestingAtHospital2";
export const PendingConfirmationNumber = "PendingConfirmationNumber";
export const CaseAmendmentTracker = "CaseAmendmentTracker";
export const InpatientAdmitType = "InpatientAdmitType";
export const VendorConfirmationDue = "VendorConfirmationDue";
export const WQCaseCounts = "WQCaseCounts";
export const CASE_AMENDMENT_TRACKER = "CASE_AMENDMENT_TRACKER";
export const CMS_INPATIENT_ONLY = "CMS_INPATIENT_ONLY";
export const PENDING_CONFIRMATION_NUMBER = "PENDING_CONFIRMATION_NUMBER";
export const INSURANCE_AUTHORIZATION = "INSURANCE_AUTHORIZATION";
export const PRESURGICAL_TESTING_AT_HOSPITAL = "PRESURGICAL_TESTING_AT_HOSPITAL";
export const PREADMISSION_TESTING_AT_HOSPITAL = "PREADMISSION_TESTING_AT_HOSPITAL";
export const LBL_SELECT_WORK_QUEUE = "Select a work queue";
export const BADGE_MAX_CONTENT = 10000;
export const SELECT_PAGE = "Select page:"
export const VENDOR_CONFIRMATION_DUE = "VENDOR_CONFIRMATION_DUE"
export const LBL_CONFIRMED = "Confirmed"
export const LBL_UNCONFIRMED = "Unconfirmed"
export const COLUMN_PRODUCT = "Product"
export const COLUMN_VENDOR = "Vendor"
export const COLUMN_CONF_STATUS = "Confirmation Status"
export const COLUMN_CONF_BY = "Confirmed By"
export const COLUMN_CONF_ON = "Confirmed On"
export const VENDOR_CONF_DIALOG_TITLE = "Vendor Confirmation Request"
export const VENDOR_CONF_DIALOG_DESCRIPTION = "Do you want to send vendor confirmation request for the unconfirmed products?"
export const VENDOR_CONF_SEND_REQUEST_SUCCESS = {title: "Vendor Confirmation Request", message: "Successfully sent request for confirmation."}
export const VENDOR_CONF_SEND_REQUEST_FAILURE = {title: "Vendor Confirmation Request Error", message: "Failed to sent request for confirmation."}
export const VENDOR_CONF_SEND_REQUEST_NO_PRODUCT = {title: "Product Not Found", message: "Products are not available for confirmation."}
export const VENDOR_CONF_CASE_INCOMPLETE = {title: "Case Incomplete", message: "Case is incomplete."}
export const LBL_LAST_NOTIFICATION_SENT = "Vendor Confirmation Request Email Sent By "

//CAT lbl
export const LBL_ACKNOWLEDGE_EHR = "Acknowledge EHR";
export const LBL_ACKNOWLEDGEMENT_IN_EMR = "Acknowledgement in EMR";
export const LBL_OLD_VALUE = "Old Value";
export const LBL_NEW_VALUE = "New Value";
export const LBL_AMENDED_BY = "Amended By:";
export const LBL_AMENDED_DATE_AND_TIME = "Amended Date and Time:";
export const LBL_PROCEDURE_DATE = "Procedure Date";
export const LBL_PATIENT_NAME = "Patient Name";
export const LBL_SURGEON = "Surgeon";
export const LBL_PROVIDER = "Provider";
export const LBL_SURGICAL_LOCATION = "Surgical Location";
export const LBL_HOSPITAL_NAME = "Hospital Name";
export const LBL_MRN_HOSPITAL = "MRN (Hospital)";
export const LBL_FIELD_AMENDED = "Field Amended";
export const LBL_PAGE_AMENDED = "Page Amended";
export const LBL_FIELD_NAME = "Field Name";
export const LBL_NO_CASE_FOUND = "No cases found";
export const LBL_INSURANCE_AUTHORIZATION_STATUS = "Insurance Authorization Status";
export const LBL_CASE_STATUS = "Case Status";
export const LBL_ADMISSION_TYPE = "Admission Type";
export const LBL_INPATIENT_CODE = "CMS Inpatient CPT codes";
export const LBL_COMPLIANCE_STATUS = "Compliance Status";
export const COMPLIANT = "Compliant";
export const NON_COMPLIANT = "Non Compliant";
export const INCOMPLETE = "Incomplete";

//login and logout tracking constants
export const IDLE = "idle";
export const SESSION_TIME_OUT = "sessiontimeout";

export const DEFAULT_PERMISSION = "DEFAULT_PERMISSION";
export const ERR_UNSAVED_CHANGES_ON_BROWSER_EVENT = "There may be unsaved changes to this case. Click OK to continue without saving your changes or cancel to return to case.";

//Dashboard page constants
export const LBL_STATUS = "Status";
export const LBL_TIME = "Time";
export const LBL_DOB = "DOB";
export const LBL_PROCEDURA_LIST = "Proceduralist";
export const LBL_PROCEDURE_TIME = "Procedure Time";
export const LBL_LOCATION = "Location";
export const LBL_MEDTEL_CASE_ID = "Medtel Case ID";
export const LBL_PROCEDURE_UNIT = "Procedure Unit";
export const LBL_CONFIRMATION_HASH = "Confirmation #";
export const LBL_HASH_OF_ATTACHMENTS = "# of Attachments";

//Case summary constant
export const LBL_CASEID = "CaseId";

//Modal Popup Constant
export const LBL_CLOSE = "Close";
export const LBL_VERIFY = "Verify";
export const LBL_OK = "OK";
export const LBL_CASEID_WITH_COLON_AND_SPACE = "Case ID: ";
export const LBL_DIALOG_CONFIRM_CONTENT_TEXT = "Are you sure you want to verify the insurance authorization of "
export const LBL_DIALOG_TITLE_CONFIRM_BOX = 'Prior Auth Verification';
export const LBL_DIALOG_ALERT_CONTENT_TEXT = "Information in this case has been amended, click OK to refresh the page."

//User Profile Screen Constants
export const LBL_HOME_ORGANIZATION = "Home Organization";
export const LBL_EMAIL_ADDRESS = "Email Address";
export const LBL_PHONE_NUMBER = "Phone Number";
export const LBL_ALTERNATE_PHONE_NUMBER = "Alternate Phone Number";
export const LBL_AFFILIATED_HOSPITALS = "Affiliated Hospitals";
export const LBL_AFFILIATED_SURGEONS = "Affiliated Surgeons";
export const LBL_LAST_LOGGED_IN = "Last Logged In";
export const LBL_USER_SINCE = "User Since";
export const LBL_SAVE = "Save";

//Data Managemant screen
export const LBL_DATA_UPLOAD = "Data Upload";
export const LBL_DATA_REPORT = "Data Report";
export const LBL_DATA_UPLOAD_SELECTION = "Data Upload Selection";
export const LBL_DRY_RUN_STATUS = "Dry Run Status";
export const LBL_MAIN_RUN_STATUS = "Main Run Status";
export const LBL_ROW_NUMBER = "Row Number";
export const LBL_ROW_STATUS = "Row Status";
export const LBL_MESSAGE = "Message";
export const LBL_SELECT_HEALTH_SYSTEM = "Select Health System";
export const INFO_USER_CREATED = "User Created.";
export const INFO_USER_UPDATED = "User Updated.";
export const ERR_NO_DATA_FOUND = "No Data Found";
export const ERR_PLZ_SELECT_A_VALID_CSV_FILE = "Please upload a “.csv” (comma separated value) file."
// action
export const ADD = "ADD";
export const REMOVE = "REMOVE";
export const CREATE = "CREATE";
export const MAINRUN = "MainRun";
// Status
export const PASS = "Pass";

// Resposne Messaged
export const REPORT_DOWNLOADED_MESSAGE = "Report downloaded successfully!";
export const NO_CONTENT_RECEIVED = "Request successfully completed but no content received!";

// Default Enabled Fields
export const DEFAULT_ENABLE_FIELD_LIST = ['ViewDocBtn', 'DownloadDocBtn', 'DocViewCloseBtn'];

// Document Alert message
export const DOCUMENT_PAGE_ALERT = "Information on this page has been amended, click OK to refresh the page."
export const EHR_INTEGRATION_ALERT = "Fetching information from the EHR System. Please wait."

// API response status code
export const NO_CONTENT_404 = 404;
export const NETWORK_ERROR_500 = "Network Error";

// Button iniside Popups
export const pupopButton = ['DocFinalRejectBtn', 'DocFileUploadUploadBtn', 'DeleteDocumentYesBtn', 'AddTrayBtn', 'SaveProductAndTrayBtn'];

// Static Massages  
export const NO_VALUE_FOUND = 'No values found for this table.';
export const RETURN_URL = 'RETURNURL';
export const REP_CONFIRMATION = 'repConfirmation';

// Control Props
export const PERMISSION_TYPE_ID = 'permissionTypeId';

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ORDER_BY = 'ProcedureDate';
export const DEFAULT_NOINSURANCEAUTH_ORDER_BY = 'VerifiedBl';
export const COLUMNS_TO_HIDE_SORT_ICON = ['SchedulingCommentsForHospitalUseOnly'];

// Search Filters for Pending scheduling confirmation workqueue
export const SEARCH_FILTER_CONTROL_LIST = [
  { 'Field': 'PrimarySurgeonList', displayLabel: 'Surgeon', displayName: 'SurgeonName', displayId: 'SurgeonId', mappingId: 'providers' },
  { 'Field': 'SurgicalLocationList', displayLabel: 'Hospital Name', displayName: 'SurgicalLocationName', displayId: 'SurgicalLocationId', mappingId: 'procedureLocation' },
  { 'Field': 'ProcedureUnitList', displayLabel: 'Procedure Unit', displayName: 'ProcedureUnitName', displayId: 'ProcedureUnitId', mappingId: 'procedureUnit' },
  { 'Field': 'CaseStatusList', displayLabel: 'Case Status', displayName: 'CaseStatusName', displayId: 'CaseStatusId', mappingId: 'caseStatus' },
];
export const APPLY_LABEL = 'Apply';
export const RESET_LABEL = 'Reset';
export const APPLY_FILTER_LABEL = 'Apply Filters';
export const RESET_FILTER_LABEL = 'Reset Filters';
export const DASHBOARD_FILTER_CONTROLS = [{ 'Field': 'PrimarySurgeonList', "additionalData": "", 'displayLabel': 'Surgeon' }, { 'Field': 'CaseStatusList', "additionalData": "DASHBOARD", 'displayLabel': 'Status' }];
export const INSURANCE_REPORT_FILTER_CONTROLS = [
  { 'Field': 'PrimarySurgeonList', "additionalData": "" },
  { 'Field': 'SurgicalLocationList', "additionalData": "" },
  { 'Field': 'ProcedureUnitList', "additionalData": "" },
  { 'Field': 'CaseStatusList', "additionalData": "WQ_INSURANCE_AUTHORIZATION" }
];
export const SCHEDULING_REPORT_FILTER_CONTROLS = [
  { 'Field': 'PrimarySurgeonList', "additionalData": "" },
  { 'Field': 'SurgicalLocationList', "additionalData": "" },
  { 'Field': 'ProcedureUnitList', "additionalData": "" },
  { 'Field': 'CaseStatusList', "additionalData": "WQ_SCHEDULING_CONFIRMATION_NUMBER" }
];

//Reports Insurane Authorization
export const ESTIMATE_SENT_CONFIRMATION = "Estimate sent confirmation.";
export const CONFIRM = "confirm";
export const UNCONFIRM = "unconfirm";
export const YES = "Yes";
export const NO = "No";
export const PermissionTypes = {
  DISABLE_FIELD: 5,
  ENABLE_FIELD: 6,
  HIDE_FIELD: 2
};
export const dialogDescriptionTemplate = (action, patientName) => `Are you sure you want to ${action} that the patient estimate was sent to ${patientName}?`;

//Document management section

export const LBL_DOC_TYPE = "Type";
export const LBL_DOC_DESCRIPTION = "Description";
export const LBL_DOC_UPLOADED_BY = "Uploaded By";
export const LBL_DOC_FAX_STATUS = "Fax Status";
export const LBL_DOC_STATUS = "Status";
export const LBL_DOC_ACTIONS = "Actions";

export const LBL_PACKET_INCLUDES = "Packet Includes:";
export const LBL_SIGNATURE_DATE = "Signature Date:";
export const LBL_RECORDS_NOT_FOUND = "Records not found";
export const DOC_EXPIRED = "Expired";
export const DOC_ACCEPTED = "Accepted";
export const DOC_REJECTED = "Rejected";
export const DOC_EXPIRED_TEXT = "Document Expired";
export const DOC_REJECT_BTN_LBL = "Reject";
export const DOC_CANCEL_BTN_LBL = "Cancel";
export const H_AND_P_PACKET_NAME = "H and P";
export const DOC_DELETE_BTN_OK = "YES, DELETE IT";
export const DOC_DELETE_BTN_CANCEL = "NO";
export const DELETE_POP_UP_HEADER = "Delete Document";
export const REJECT_POP_UP_HEADER = "Reject Document";
export const UPLOAD_POP_UP_HEADER = "Upload Document";
export const VIEW_POP_UP_HEADER = "View Document";
export const UPLOAD_BTN_LBL = "Upload Document";
export const EDIT_BTN_LBL = "Parse Doc";
export const RETRYFAX_BTN_LBL = "Retry Fax";
export const ACCEPT_BTN_LBL = "Accept";
export const REJECT_BTN_LBL = "Reject";
export const DOC_STATUS_PENDING_REVIEW = "Pending Review";
export const DOC_PREVIEW_LBL = "Preview Document";
export const DOC_SECTION_HEADER = "Document Management";
export const DOC_UPDATE_BTN_LBL = "Update";

export const REJECT_BTN_MAPPING_ID = "RejectDocBtn";
export const APPROVE_BTN_MAPPING_ID = "ApproveDocBtn";
export const RETRYFAX_BTN_MAPPING_ID = "RetryFaxBtn";
export const EDITDOC_BTN_MAPPING_ID = "EditDocUrl";
export const DELETE_BTN_MAPPING_ID = "DeleteDocBtn";
export const VIEW_BTN_MAPPING_ID = "ViewDocBtn";
export const DOWNLOAD_BTN_MAPPING_ID = "DownloadDocBtn";
export const UPLOAD_BTN_MAPPING_ID = "UploadDocBtn";
export const FAX_DETAILS_MAPPING_ID = "DocDetailsFaxedDetails";
export const DOC_REJECTION_MAPPING_ID = "DocRejectionReason";
export const DOC_TYPE_MAPPING_ID = "DocumentTypeId";
export const DOC_SIGNED_DATE_MAPPING_ID = "DocumentSignedDate";
export const DOC_DESCRIPTION_MAPPING_ID = "DocDescription";
export const DOC_NOTES_MAPPING_ID = "DocNotes";
export const DOC_UPLOAD_BTN_MAPPING_ID = "DocFileUploadUploadBtn";
export const DOC_FILE_MAPPING_ID = "DocFileSelection";
export const DOC_CANCEL_BTN_MAPPING_ID = "DocFileUploadCancelBtn";
export const DOC_FILE_SAVE_MAPPING_ID = "DocFileUploadSaveBtn";
export const DOC_FILE_SELECTION_EDIT_MAPPING_ID = "DocFileSelectionEdit";
export const ADD_TYPE_TEXT = "Add Type";
export const UPLOAD_DOC_SECTION_NAME = "Document Upload";
export const REJECT_DOC_SECTION_NAME = "Reject Document";
export const EDIT_DOC_SECTION_NAME = "Edit Document";
export const HNP_EXPIRE_DT_LBL = "Document Expiration Date (MM/DD/YYYY): ";
export const PAGE_RANGE_MAPPING_ID = "ChildDocumentPageRange";

export const INVALID_FILE_MSG = "Invalid File";
export const INVALID_PAGE_RANGE_MSG = "Invalid page range";
export const DELETE_CONFIRM_MSG = "Are you sure you want to delete the document ?";
export const DELETE_SUCCESS_MSG = "Document deleted successfully.";
export const DOC_HNP_GT_MSG = "Under Hospital Guidelines, the <doctype> will not be valid on date of surgery. Please upload the <doctype> within <d> days of the Procedure Date."
export const SUPPORTED_FILE_TYPE_MSG = "The provided file type is not supported by Medtel. Please upload one of the following file types for optimal transmission: "
export const FILE_TYPES_FOR_PREVIEW = ['.jpeg', '.jpg', '.pdf', '.png', '.gif', '.tiff', '.tif']
export const PARSE_DOC_MAPPING_ID = "ParseDocumentBl"
export const PARSE_DOC_DESCRIPTION = "By parsing this packet, this will be split into multiple files. This can't be undone."
export const PACKET_DOC_TYPE_NAME = "Packet"
export const UPDATE_DOC_FAILURE_MSG = "Document update failed. Please try again."
export const DOC_SIGNED_DATE_LBL_MAPPING_ID = "SelectedDocumentSignedDate"
export const DOC_FAX_SUCCESS = "Successfully Transmitted"
export const DOC_FAX_TRANSMITTING = "Transmitting"
export const DOC_FAX_FAILED = "Failed Transmitting"
export const LBL_DOC_NOTES = "Notes"
export const LBL_DOC_FILE_NAME = "File Name"
export const LBL_GETTING_PREVIEW = "Getting Preview.."
export const LBL_SELECTED_FILE = "Selected File:"
export const AFTER_DATE_MSG = "Date should be same or less than current date."



import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import Loader from '../common/Loader';

const ReportDialogBox = (props) => {
    const { open, handleYes, handleClose, loading, title, content, buttonOkText, buttonCancelText } = props;

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle >
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleClose} loading={loading}>{buttonCancelText || 'No'}</LoadingButton>
                    <LoadingButton onClick={handleYes} loading={loading}>
                        {buttonOkText || 'Yes'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportDialogBox

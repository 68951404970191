import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import { Box, Collapse, IconButton, Typography, TableSortLabel, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportDialogBox from './ReportDialogBox';
import {
    getLoadingStatus,
    getItemsPerPage,
    getPageNumber,
    getTotalCases,
    getTableHeaderConfig,
    getSortData
} from '../../reducers/ReportsReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import {
    getPendingVendorConfirmationData,
    UpdateFieldSorting,
    updatePageNumber,
    sendPendingVendorConfirmationRequest
} from '../../actions/ReportsAction';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants/GlobalConstants';
import * as Colors from '../../res/values/Colors';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import TablePaginationExtended from './TablePaginationExtended';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Controls from '../controls/Controls';

const useStyles = makeStyles(theme => ({
    loader: {
        margin: 0
    },
    innerTableContainer: {
        maxHeight: '300px',
        overflow: 'auto'
    },
    innerTableCell: {
        backgroundColor: Colors.AMENDMENTS_LIST_HEADER,
    },
    innerTableHeader: {
        backgroundColor: Colors.AMENDMENTS_LIST_HEADER,
        position: 'relative',
        zIndex: 0
    },
    tableContainer: {
        maxHeight: `calc(100vh - ${theme.spacing(32)})`
    },
    tableHeader: {
        backgroundColor: Colors.CAT_TABLE_HEADER
    },
    colorConfirm: {
        color: Colors.ICON_GR
    },
    colorUnconfirm: {
        color: Colors.ICON_RED
    },
    sortLabelRoot: {
        color: `${theme.palette.common.white} !important`, // Customize root color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color
        },
    },
    sortLabelIcon: {
        color: `${theme.palette.common.white} !important`, // Customize icon color
        '&.MuiTableSortLabel-active': {
            color: `${theme.palette.common.white} !important`, // Active state color for icon
        },
    },
}))

const TableCellHead = withStyles((theme) => ({
    root: {
        backgroundColor: Colors.CAT_TABLE_HEADER,
        color: theme.palette.common.white,
        minWidth: 80
    },
}))(TableCell);

const vendorConfDialogInfoInit = { caseId: null, open: false }

export default function VendorConfirmationDue(props) {
    const { data } = props;
    const loading = useSelector(getLoadingStatus)
    const pageNumber = useSelector(getPageNumber)
    const itemsPerPage = useSelector(getItemsPerPage)
    const totalCases = useSelector(getTotalCases)
    const tableHeader = useSelector(getTableHeaderConfig);
    const headCells = tableHeader?.headers
    const [vendorConfDialogInfo, setVendorConfDialogInfo] = React.useState(vendorConfDialogInfoInit);

    //initialize sorting
    var sortRequestData = useSelector(getSortData);
    var sortRequestDataOrder = null
    var sortRequestDataOrderBy = null
    sortRequestData && sortRequestData.forEach(element => {
        sortRequestDataOrder = element ? element.Order : null;
        sortRequestDataOrderBy = element ? element.Field : null;
    });
    var sortOrder = sortRequestDataOrder ? sortRequestDataOrder : Constants.DEFAULT_ORDER;
    var sortOrderBy = sortRequestDataOrderBy ? sortRequestDataOrderBy : Constants.DEFAULT_ORDER_BY;
    const [order, setOrder] = React.useState(sortOrder);
    const [orderBy, setOrderBy] = React.useState(sortOrderBy);

    const dispatch = useDispatch()
    const classes = useStyles()

    React.useEffect(() => {
        setOrder(sortOrder);
        setOrderBy(sortOrderBy);
    }, [sortOrderBy]);

    const handleClose = () => {
        setVendorConfDialogInfo(vendorConfDialogInfoInit)
    };

    const handleYes = () => {
        dispatch(sendPendingVendorConfirmationRequest(vendorConfDialogInfo.caseId))?.then(handleClose)
    };

    const sortByColumnID = (e, newOrderBy) => {
        const isAsc = orderBy === newOrderBy && order.toLowerCase() === 'asc';
        const toggledOrder = isAsc ? 'desc' : 'asc';
        setOrder(toggledOrder);
        setOrderBy(newOrderBy);
        var sortData = [];
        var sortItem = { 'Field': newOrderBy, 'Order': toggledOrder };
        sortData.push(sortItem);
        dispatch(UpdateFieldSorting(sortData));
        dispatch(updatePageNumber(0));
    }

    const openDialog = (
        <ReportDialogBox
            open={vendorConfDialogInfo.open}
            handleYes={handleYes}
            handleClose={handleClose}
            loading={false}
            title={Constants.VENDOR_CONF_DIALOG_TITLE}
            content={Constants.VENDOR_CONF_DIALOG_DESCRIPTION}
        />
    )

    // `RenderProductList` is used to render the product data upon caret icon click
    const RenderProductList = (props) => {
        const { row } = props;
        
        return (
            <>
                <Box sx={{ margin: 3 }}>
                    <Stack direction="row" display="flex" justifyContent="space-between" spacing={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong> {Constants.LBL_PRODUCTS} </strong> {row.CreatedBy}
                        </Typography>
                        <Stack direction="row" display="flex" alignItems="center" spacing={3}>
                            <Box>
                                {row.lastSentInfo?.updatedBy && (
                                    <Stack direction="row" spacing={1} display="flex" alignItems="center">
                                        <Typography variant="subtitle2" sx={{fontStyle: 'italic'}}>{`${Constants.LBL_LAST_NOTIFICATION_SENT} ${row.lastSentInfo.updatedBy} On ${moment(row.lastSentInfo.lastUpdatedOn).format(Constants.LAST_SENT_DATE_TIME_FORMAT)}`}</Typography>
                                    </Stack>
                                )}
                            </Box>
                            <Box>
                                {row.SendNotificationBl && !row.VendorConfirmationStatusBl && (
                                    <Button disabled={row.productInfo.length == 0} variant="contained" size="small" onClick={() => setVendorConfDialogInfo({ caseId: row.CaseId, open: true })}>Send Request</Button>
                                )}
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{ margin: 3 }}>
                    <TableContainer
                        component={Paper}
                        className={classes.innerTableContainer}
                    >
                        <Table size="small" stickyHeader>
                            <TableHead className={classes.innerTableHeader}>
                                <TableRow>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.COLUMN_PRODUCT}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.COLUMN_VENDOR}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.COLUMN_CONF_STATUS}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.COLUMN_CONF_BY}
                                    </TableCell>
                                    <TableCell className={classes.innerTableCell}>
                                        {Constants.COLUMN_CONF_ON}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.productInfo.length > 0 ? row.productInfo.map(info => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {info.manufacturerName ? `${info.productName} - ${info.manufacturerName}`: info.productName}
                                        </TableCell>
                                        <TableCell>{info.vendorName}</TableCell>
                                        <TableCell><ShowStatus status={info.vendorConfirmationStatusBl} /></TableCell>
                                        <TableCell>{info.confirmedbyVendorName}</TableCell>
                                        <TableCell>{`${info.confirmationDate !== null ? info.confirmationDate : ''} ${info.confirmationTime !== null ? info.confirmationTime : ''}`}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell scope="row" colSpan="5">
                                            {Constants.LBL_RECORDS_NOT_FOUND}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>
        )
    }

    //Show confirmation status
    const ShowStatus = ({ status }) => {
        return (
            <>
                {status ? (
                    <Stack direction="row" spacing={0.5} display="flex" alignItems="center">
                        <CheckCircleIcon className={classes.colorConfirm} />
                        <Box className={classes.colorConfirm}>{Constants.LBL_CONFIRMED}</Box>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={0.5} display="flex" alignItems="center">
                        <ErrorIcon className={classes.colorUnconfirm} />
                        <Box className={classes.colorUnconfirm}>{Constants.LBL_UNCONFIRMED}</Box>
                    </Stack>
                )}
            </>
        );
    }

    const RenderTableBody = (props) => {
        const { row, index } = props;

        const handleGetPendingVendorConfirmation = (CaseId, Open) => {
            dispatch(getPendingVendorConfirmationData(CaseId, Open))
        }

        return (
            <>
                <TableRow hover>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleGetPendingVendorConfirmation(row.CaseId, row.open)}
                        >
                            {row.open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.PatientName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.PrimarySurgeonName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.HospitalName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.ProcedureUnitName}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.ProcedureDate}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        <Link to={`case/${parseInt(row.CaseId)}/ImplantsandProducts`}>
                            {row.CaseId}
                        </Link>
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        {row.CaseStatus}
                    </TableCell>
                    <TableCell component="tb" scope="row" align="left">
                        <ShowStatus status={row.VendorConfirmationStatusBl} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: 0 }} colSpan={12}>
                        <Collapse in={row.open} timeout="auto" unmountOnExit>
                            {row.productLoading ? (
                                <Box className={classes.loader}>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                <RenderProductList row={row} />
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <>
            {openDialog}
            <Controls.Snackbar />
            <Paper elevation={2} sx={{ width: '100%', overflow: 'hidden', marginBottom: 2 }}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCellHead align='center' sx={{ minWidth: '50px !important' }} ></TableCellHead>
                                {headCells && headCells.map((headCell) => (
                                    <TableCellHead align='left' >
                                        <TableSortLabel
                                            active={orderBy === headCell.Alias}
                                            direction={orderBy === headCell.Alias ? order.toLowerCase() : 'asc'}
                                            onClick={(e, value) => sortByColumnID(e, headCell.Alias)}
                                            classes={{
                                                root: classes.sortLabelRoot,
                                                icon: classes.sortLabelIcon
                                            }}
                                        >
                                            {headCell.ColumnName}
                                        </TableSortLabel>
                                    </TableCellHead>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan="9" >
                                        <Loader loading={loading} />
                                    </TableCell>
                                </TableRow>
                                : ((data.length !== 0) ? data.map((row, i) => (<RenderTableBody row={row} index={i} key={i} />)) :
                                    (<TableRow>
                                        <TableCell component="th" scope="row" colSpan="10" >
                                            {Constants.LBL_NO_CASE_FOUND}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePaginationExtended
                    totalCases={totalCases}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                />
            </Paper>
        </>
    );
}

import React, { useEffect } from 'react'
import {
    Button,
    ButtonGroup,
    Badge,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CASE_NAVIGATION_TITLE } from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import Controls from '../controls/Controls'
import { getStatusCount } from '../../reducers/ReportsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateFieldSorting, getReportsCases, getSearchFiltersReferenceData, setActiveStatus, updatePageNumberState, resetPaginationState, getAllReportsCaseCount, resetReportsCaseCount } from '../../actions/ReportsAction'
import {
    LBL_WORK_QUEUES, LBL_INSURANCE_AUTHORIZATION, LBL_PRESURGICAL_TESTING_AT_HOSPITAL, LBL_PREADMISSION_TESTING_AT_HOSPITAL_2,
    LBL_PENDING_CONFIRMATION_NUMBER, LBL_CASE_AMENDMENT_TRACKER, NoInsuranceAuthorizationProvided,
    PreSurgicalTestingatHospital, PreAdmissionTestingAtHospital2, PendingConfirmationNumber, CaseAmendmentTracker, LBL_INPATIENT_ADMIT_TYPE, InpatientAdmitType, SCHEDULING_REPORT_FILTER_CONTROLS, INSURANCE_REPORT_FILTER_CONTROLS,
    LBL_SELECT_WORK_QUEUE, BADGE_MAX_CONTENT, LBL_VENDOR_CONFIRMATION_DUE, VendorConfirmationDue
} from '../../constants/GlobalConstants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    headline: {
        textAlign: "left",
        color: CASE_NAVIGATION_TITLE,
        marginBottom: `${Dimens.HEADLINE_MARGIN_BOTTOM} !important`
    },
    optionBtn: {
        minWidth: `${Dimens.OPTION_BTN_WIDTH} !important`
    },
    popper: {
        zIndex: Dimens.POPPER_ZINDEX,
        minWidth: Dimens.POPPER_WIDTH
    },
    lableInBadge: {
        padding: Dimens.LBL_BADGE_PADDING
    }
}))

const ReportsLeftNav = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const CasesCount = useSelector(getStatusCount);
    const reportsCasesCount = CasesCount.statusCount ? CasesCount.statusCount : null;
    const { isShowPreSurgicalTestingatHospital, isShowPreAdmissionTestingAtHospitalTab } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [reportOptions, setReportOptions] = React.useState([]);

    useEffect(() => {
        dispatch(getAllReportsCaseCount(isShowPreSurgicalTestingatHospital, isShowPreAdmissionTestingAtHospitalTab));
        return () => {
            dispatch(setActiveStatus(''))
            dispatch(resetReportsCaseCount());
        }
    }, []);

    useEffect(() => {
        // Initial state to render the nav options in drop down
        const options = [
            {
                label: LBL_INSURANCE_AUTHORIZATION,
                count: reportsCasesCount.NoInsuranceAuthorizationProvided,
                status: NoInsuranceAuthorizationProvided,
                visibility: true
            },
            {
                label: LBL_PRESURGICAL_TESTING_AT_HOSPITAL,
                count: reportsCasesCount.PreSurgicalTestingatHospital,
                status: PreSurgicalTestingatHospital,
                visibility: isShowPreSurgicalTestingatHospital
            },
            {
                label: LBL_PREADMISSION_TESTING_AT_HOSPITAL_2,
                count: reportsCasesCount.PreAdmissionTestingAtHospital2,
                status: PreAdmissionTestingAtHospital2,
                visibility: isShowPreAdmissionTestingAtHospitalTab
            },
            {
                label: LBL_INPATIENT_ADMIT_TYPE,
                count: reportsCasesCount.InpatientAdmitType,
                status: InpatientAdmitType,
                visibility: true
            },
            {
                label: LBL_VENDOR_CONFIRMATION_DUE,
                count: reportsCasesCount.VendorConfirmationDue,
                status: VendorConfirmationDue,
                visibility: true
            },
            {
                label: LBL_PENDING_CONFIRMATION_NUMBER,
                count: reportsCasesCount.PendingConfirmationNumber,
                status: PendingConfirmationNumber,
                visibility: true
            },
            {
                label: LBL_CASE_AMENDMENT_TRACKER,
                count: reportsCasesCount.CaseAmendmentTracker,
                status: CaseAmendmentTracker,
                visibility: true
            },
        ]

        setReportOptions([...options]);
    }, [
        reportsCasesCount.NoInsuranceAuthorizationProvided,
        reportsCasesCount.PreSurgicalTestingatHospital,
        reportsCasesCount.PreAdmissionTestingAtHospital2,
        reportsCasesCount.InpatientAdmitType,
        reportsCasesCount.PendingConfirmationNumber,
        reportsCasesCount.CaseAmendmentTracker,
        reportsCasesCount.VendorConfirmationDue,
    ])

    const handleCaseStatusNavigation = (status) => {
        dispatch(resetPaginationState())
        dispatch(UpdateFieldSorting([]));
        dispatch(updatePageNumberState(0))
        dispatch(setActiveStatus(status))
        dispatch(getReportsCases(status))
        if (status === PendingConfirmationNumber) dispatch(getSearchFiltersReferenceData(SCHEDULING_REPORT_FILTER_CONTROLS));
        if (status === NoInsuranceAuthorizationProvided) dispatch(getSearchFiltersReferenceData(INSURANCE_REPORT_FILTER_CONTROLS));
    }

    const handleMenuItemClick = (option, index) => {
        setSelectedIndex(index)
        handleCaseStatusNavigation(option.status)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Controls.Typography
                variant='h6'
                className={classes.headline}>{LBL_WORK_QUEUES}
            </Controls.Typography>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                {selectedIndex === null ? (
                    <Button className={classes.optionBtn}>
                        <Controls.Typography className={classes.lableInBadge}>{LBL_SELECT_WORK_QUEUE}</Controls.Typography>
                    </Button>
                ) : (
                    <Button className={classes.optionBtn} onClick={() => handleCaseStatusNavigation(reportOptions[selectedIndex].status)}>
                        <Badge badgeContent={reportOptions[selectedIndex].count} color="secondary" max={BADGE_MAX_CONTENT} showZero>
                            <Controls.Typography className={classes.lableInBadge}>{reportOptions[selectedIndex].label}</Controls.Typography>
                        </Badge>
                    </Button>
                )}
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select work queue"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {reportOptions.map((option, index) =>
                                        option.visibility ? (
                                            <MenuItem
                                                key={option.label}
                                                selected={index === selectedIndex}
                                                onClick={() => handleMenuItemClick(option, index)}
                                            >
                                                <Badge badgeContent={option.count} color="primary" max={BADGE_MAX_CONTENT} showZero>
                                                    <Controls.Typography className={classes.lableInBadge}>{option.label}</Controls.Typography>
                                                </Badge>
                                            </MenuItem>
                                        ) : null
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default ReportsLeftNav
